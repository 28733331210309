// START BUTTON OVERRIDE SCSS
// NOTE: Some of these overrides are supposed to "compete" with PrimeNG
// CSS rules by selector specificity, so they must be in the same layer.
@layer primeng {
  .p-button {
    background: var(--darkColor);
    border-color: var(--darkColor);
    &:not(:disabled) {
      &:hover {
        background: var(--hoverColor);
        border: 1px solid var(--darkColor);
      }
      &:focus {
        box-shadow: none;
      }
    }
    &.p-button-text {
      &:not(:disabled) {
        &:hover {
          background: none;
        }
      }
    }
    &.p-button-secondary {
      &:not(:disabled) {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  a.p-button {
    &:hover {
      color: var(--whiteColor);
    }
  }
  .user_list_role_button {
    .p-button {
      &.p-button-sm {
        cursor: default;
      }
    }
  }
  .account__settings-button {
    .p-button {
      &.p-button-outlined {
        color: var(--whiteColor);
        background: transparent;
        cursor: pointer;
        border: 1px solid var(--lightColor);
        &:hover {
          border: 1px solid var(--whiteColor);
          color: var(--lightColor) !important;
          background: transparent;
        }
      }
    }
  }

  .icon-button {
    &.p-button {
      display: flex;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }

  .action-button-color {
    &.p-button {
      &.p-button-text {
        color: black;
        &:enabled {
          &:hover {
            color: black;
          }
        }
      }
    }
  }

  .table__action__custom__button-hover {
    &.p-button {
      background: var(--whiteColor);
      width: 34px;
      height: 34px;
      border: 2px solid #eff2f5;
      color: var(--darkColor);
      padding: 1px 5px;

      &:enabled {
        &:hover {
          background: var(--whiteColor);
          border: 2px solid #eff2f5;
          color: var(--darkColor);
        }
      }
    }
  }

  .calculation-button {
    &.p-button {
      background: var(--whiteColor);
      border: 2px solid #eff2f5;
      color: var(--darkColor);
      padding: 4.5px 10px;

      &:enabled {
        &:hover {
          background: var(--whiteColor);
          border: 2px solid #eff2f5;
          color: var(--darkColor);
        }
      }
    }
  }
  .status-button {
    &.p-button {
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #06323b;
      background: var(--borderColor) 0 0% no-repeat padding-box;
      border-radius: var(--borderRadius);
      &:enabled {
        &:hover {
          background: var(--borderColor) 0 0% no-repeat padding-box;
          color: #06323b;
          border: none;
        }
      }
    }
  }
  .info_icon {
    &.p-button {
      &.p-button-icon-only {
        &.p-button-rounded {
          height: 28px;
          width: 28px;
        }
      }
    }
  }

  .secondary__btn__color {
    &.p-button {
      border: 1px solid var(--lightColor);
      background: var(--lightColor);
      color: var(--whiteColor);
      cursor: pointer;

      &:hover {
        border: 1px solid var(--lightColor);
        background: var(--lightColor);
        color: var(--whiteColor);
      }
    }
  }

  .bubbly-button {
    &.p-button {
      opacity: 0.8;
      transition:
        transform ease-in 0.1s,
        box-shadow ease-in 0.25s;
      &:hover {
        background: var(--darkColor);
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }

  .nlp__upload_button {
    &.p-button {
      background-color: #ffffff;
      border: none;
      padding: 10px;
      cursor: pointer;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      &:hover {
        background-color: #d3d3d3;
        border: none;
      }
    }
  }

  .panel__open__close-button.p-button {
    display: none;
    color: var(--darkColor);
    background: none;
    border: none;
    &:enabled {
      &:hover {
        color: var(--lightColor);
        border: none;
        background: none;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .panel__open__close-button.p-button {
      display: block;
    }
  }
  // END BUTTON OVERRIDE SCSS

  // START DASHBOARD SUB ITEM BUTTON OVERRIDE SCSS
  .feature-button.p-button {
    border-radius: var(--borderRadius);
    display: inline-block;
    font-size: 1em;
    padding: 0.5em 2em;
    margin: 8px 0;
    appearance: none;
    background-color: var(--darkColor);
    color: var(--whiteColor);
    cursor: pointer;
    position: relative;
    transition:
      transform ease-in 0.1s,
      box-shadow ease-in 0.25s;
    opacity: 0.8;
    width: 100%;
    box-shadow: none;
    word-break: break-word;
    &:enabled {
      &:hover {
        transform: scale(1.1);
        opacity: 1 !important;
        background-color: var(--darkColor);
      }
    }
  }

  .ccf_process_button {
    &.p-button {
      border: none;
      background: var(--lightColor);
      color: var(--whiteColor);
      cursor: pointer;
      &:hover {
        border: none;
        background: var(--darkColor);
        color: var(--whiteColor);
      }
    }
  }
  // END DASHBOARD SUB ITEM BUTTON OVERRIDE SCSS

  .copy-button {
    &.p-button {
      border: 1px solid var(--surface-ground);
      background: var(--surface-ground);
      color: black;
      cursor: pointer;
    }
  }

  .p-selectbutton {
    .p-button {
      border-color: var(--darkColor);
      &.p-highlight {
        background: var(--darkColor);
        border-color: var(--darkColor);
        color: #ffffff;
        box-shadow: none;
        &:hover {
          background: var(--darkColor);
          border-color: var(--darkColor);
          color: #ffffff;
        }
      }
    }
  }

  .p-button {
    &.p-button-outlined {
      color: var(--darkColor);
      &:enabled {
        &:hover {
          color: var(--darkColor);
        }
      }
    }
  }

  .relation-main-contact-person-button {
    &.p-button {
      &.p-button-icon-only {
        &.p-button-rounded {
          border-radius: 50%;
          height: 1.2rem;
          width: 1.2rem;
          padding: 0.65rem;
        }
      }
    }
  }

  .compare-button {
    background-color: var(--lightColor);
    border: none !important;
    color: var(--whiteColor);

    &:hover {
      background-color: var(--lightColor);
      border: none !important;
    }
  }

  .checklist-questionnaire-button {
    button {
      width: max-content;
    }
  }

  .notifier-outlined-button {
    .p-button {
      color: var(--darkColor);
      background-color: transparent;
      border: 1px solid;
    }
  }

  .task-modal-template-button {
    .p-button {
      &.p-button-outlined {
        width: 141px;
      }
      .p-button-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.button__government-requests {
  .p-button {
    background: #bd605e;
    border-color: #bd605e;
  }
}
