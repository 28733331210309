app-common-chips,
app-entity {
  .p-autocomplete,
  .p-component {
    .p-autocomplete-multiple-container,
    .p-chips-multiple-container {
      width: 100%;
      overflow: auto;
      align-items: flex-start;
      gap: 5px;
      &:hover,
      &:focus,
      &:focus-within {
        border-color: #ced4da !important;
        box-shadow: 0 0 0 0 !important;
        outline: none !important;
      }
      .p-autocomplete-input-token,
      .p-chips-input-token {
        padding: 0 !important;
        input {
          border: 0 !important;
          padding: 5px !important;
          &:focus {
            box-shadow: 0 0 0 0 !important;
            outline: none !important;
          }
        }
      }
    }
  }
}

.common-chips {
  .p-autocomplete-multiple-container {
    min-height: 70px;
    overflow: auto;
    align-items: flex-start;
    gap: 5px;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    margin-right: 0;
    input {
      border: 0 !important;
      padding: 0 !important;
      &:focus {
        box-shadow: 0 0 0 0 !important;
        outline: none !important;
      }
    }
  }
}

.search-bar {
  .p-autocomplete-multiple-container {
    overflow: auto;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: #ced4da !important;
      box-shadow: 0 0 0 0 !important;
      outline: none !important;
    }
  }
}

#questionnaire-chips .p-chips,
#questionnaire-chips .p-chips-multiple-container,
#private-minfin-chips .p-chips,
#private-minfin-chips .p-chips-multiple-container,
#company-minfin-chips .p-chips,
#company-minfin-chips .p-chips-multiple-container {
  min-width: 25rem !important;
}
