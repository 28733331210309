html {
  height: 100%;
  font-size: 14px;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  min-width: 320px;
  background: #fff;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Poppins', serif !important;
}

::selection {
  background: #c1e2e9;
  color: var(--darkColor);
}

* {
  scrollbar-width: thin;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
}

h1 {
  min-height: 1rem;
}

@for $i from 1 through 5 {
  $selector: 'h#{$i}';
  $baseFontSize: 2rem;

  #{$selector} {
    font-size: $baseFontSize - 0.28571429 * ($i - 1);
  }
}

@for $i from 1 through 6 {
  h#{$i}:first-child {
    margin-top: 0;
  }
}

@for $i from 1 through 6 {
  h#{$i}:last-child {
    margin-bottom: 0;
  }
}

p {
  margin: 0 0 1em;
  line-height: 1.4285em;
  &:last-child {
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: #4183c4;
  text-decoration: none;
  &:hover {
    color: #1e70bf;
    text-decoration: none;
  }
}
