.custom__modal {
  //DATE PIKER
  .mat-calendar-body-selected {
    background-color: var(--user-background) !important;
    color: var(--user-text) !important;
  }

  .mat-datepicker-toggle-default-icon {
    color: var(--user-background) !important;
  }

  .mat-datepicker-popup {
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0 !important;
  }

  .mat-form-field-wrapper {
    padding: 0 !important;
  }

  .mat-datepicker-toggle-default-icon {
    outline: none;
    height: 24px !important;
    width: 24px !important;
  }

  .pac-container {
    margin-top: -200px;
  }
}

// ToDo: make new scss branch for mat overriding
// mat tool tip override
mat-tooltip-component {
  & .mat-tooltip {
    background: var(--whiteColor); // your custom properties here.
    color: #1e1e1e;
    font-size: 12px;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }
}
