.p-fileupload {
  border: 1px dotted var(--darkColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
  min-height: 95px;

  p-messages {
    .p-messages {
      margin-top: 70px;
    }
  }

  .p-progressbar {
    display: none;
  }

  .p-fileupload-buttonbar {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 0;
    z-index: 0;

    .p-fileupload-choose {
      display: flex;
      gap: 5px;
      align-items: center;
      flex-direction: column;

      .p-button-icon {
        font-size: 24px;
      }
    }

    .p-button {
      background-color: transparent;
      border: 0;
      color: var(--darkColor);
    }

    .p-button-label {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .p-fileupload-content {
    background: transparent;
    border: 0;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 1rem;
  }

  .p-fileupload-row {
    display: flex;
    align-items: center;
    margin-top: 35px;
  }
}
