@mixin pi-custom-svg($url) {
  &::before {
    display: inline-block;
    width: 1em;
    height: 1em;

    background-color: currentColor;
    mask-image: url($url);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;

    content: '';
  }
}

.pi-sidebar-expand {
  @include pi-custom-svg('../../../images/icon-sidebar-expand.svg');
}

.pi-sidebar-collapse {
  @include pi-custom-svg('../../../images/icon-sidebar-collapse.svg');
}

.pi-wand {
  @include pi-custom-svg('../../../images/icon-wand.svg');
}
