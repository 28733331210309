.p-datatable {
  .p-datatable-header {
    padding: 0.5rem 0;
  }
  &.p-datatable-sm {
    .p-datatable-header {
      padding: 0.5rem 0;
    }
  }
  .p-datatable-header {
    border: none;
    background: none;
  }
  .p-paginator-top {
    border: none;
    padding-bottom: 1rem !important;
  }
  .p-sortable-column {
    &.p-highlight {
      color: var(--lightColor);
      &:hover {
        color: var(--lightColor);
        .p-sortable-column-icon {
          color: var(--lightColor);
        }
      }
      .p-sortable-column-icon {
        color: var(--lightColor);
        &:hover {
          color: var(--lightColor);
        }
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 0.15rem #a3e2db;
    }
  }
}

.p-paginator {
  justify-content: flex-end !important;
  padding: 1rem 0.5rem 0.5rem;
  .p-paginator-pages {
    .p-paginator-page {
      &.p-highlight {
        background: #c1e2e9;
        border-color: #c1e2e9;
        color: var(--darkColor);
      }
    }
  }
}

.submission-table.p-datatable {
  &.p-datatable-sm {
    .p-datatable-tbody {
      & > tr {
        & > td {
          padding: 1rem 0.5rem;
        }
      }
    }
  }
}

.ready-to-submit-table {
  .p-paginator-left-content {
    width: 50%;
  }
}

.statistics-table.p-datatable {
  .p-datatable-thead {
    & > tr {
      & > th {
        padding: 0.5rem 1rem;
      }
    }
  }
  .p-datatable-tbody {
    & > tr {
      & > td {
        padding: 1.35rem 1rem;
      }
    }
  }
}

.scroll-height.p-datatable-scrollable {
  .p-datatable-tbody {
    max-height: 550px;
  }
}

.document-allocation-table {
  .p-datatable-wrapper {
    overflow: unset !important;
  }
}

.import-tabwise-customers-table {
  .p-datatable-footer {
    background: none;
    border: 0 none;
    padding: 0;
  }
}

.import-valid-customers-table {
  .p-datatable-footer {
    background: none;
    border: 0 none;
    padding: 0;
  }
}

.email-setting-content-table {
  &.p-datatable {
    .p-datatable-header {
      padding: 1rem 0;
    }
  }
}

.compare-tab-table.p-datatable,
.attachment-tab-table.p-datatable,
.minfin-documents-table.p-datatable {
  .p-datatable-thead {
    & > tr {
      & > th {
        padding: 0.5rem;
      }
    }
  }
  .p-datatable-tbody {
    & > tr {
      & > td {
        padding: 0.5rem;
      }
    }
  }
}

.attachment-tab-table tbody {
  .block {
    display: contents !important;
  }
}

.minfin-documents-table tbody {
  .block {
    display: contents !important;
  }
}

.aos-customers-tab-panels {
  div {
    p-table {
      div {
        .p-datatable-header {
          padding: 1.5rem 0 1.5rem 0 !important;
        }
      }
    }
  }
}

.attachment-tab-table,
.minfin-documents-table {
  max-height: calc(100vh - 400px);
  overflow: auto;
  &.p-datatable-scrollable .p-datatable-thead > tr > th,
  &.p-datatable-scrollable .p-datatable-tbody > tr > td,
  &.p-datatable-scrollable .p-datatable-tfoot > tr > td {
    flex: none !important;
  }
}

.simulation-table {
  max-height: 560px;
  overflow: auto;
}

.simulation-table {
  max-height: 560px;
  overflow: auto;
}

.e-signer-table {
  max-height: 370px;
  overflow: auto;
}

@media screen and (max-width: 960px) {
  .trainings-table {
    .p-datatable-tbody > tr > td {
      display: unset !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .comments-table {
    .p-datatable-tbody > tr > td {
      justify-content: center !important;
    }
  }

  .individual-sync-table {
    .p-datatable-tbody > tr > td {
      justify-content: center !important;
    }
  }
}
