.p-breadcrumb {
  border: none;
  padding: 0;

  ul {
    li {
      .p-menuitem-link:focus {
        box-shadow: none;
      }
    }
  }
}

.p-breadcrumb-list {
  min-height: 1.5rem;
}
