.p-timeline-event-opposite {
  display: none;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem 1rem 1rem;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
  line-height: 1;
}
