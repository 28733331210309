// START DATEPICKER OVERRIDE SCSS
.p-datepicker {
  table {
    td {
      & > span {
        &.p-highlight {
          background: var(--lightColor);
          color: var(--whiteColor);
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .p-datepicker-header {
    .p-datepicker-title {
      .p-datepicker-month {
        &:enabled {
          &:hover {
            color: var(--darkColor);
          }
        }
      }
      .p-datepicker-year {
        &:enabled {
          &:hover {
            color: var(--darkColor);
          }
        }
      }
    }
    .p-datepicker-prev {
      &:focus {
        box-shadow: 0 0 0 0.2rem #5b8d97;
      }
    }
    .p-datepicker-next {
      &:focus {
        box-shadow: 0 0 0 0.2rem #5b8d97;
      }
    }
  }
  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        background: none;
      }
    }
  }
  .p-yearpicker {
    .p-yearpicker-year {
      &.p-highlight {
        background: var(--lightColor);
        color: var(--whiteColor);
      }
    }
  }
  &:not(.p-disabled) {
    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled):focus {
          box-shadow: 0 0 0 0.2rem #5b8d97;
        }
        &:not(.p-disabled):not(.p-highlight):hover {
          background: none;
        }
      }
    }
    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled):focus {
          box-shadow: 0 0 0 0.2rem #5b8d97;
        }
        &:not(.p-disabled):not(.p-highlight):hover {
          background: none;
        }
      }
    }
  }
}

.task-due__date__picker.p-calendar {
  .p-inputtext {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

app-automatic-reminder-modal {
  p-calendar {
    .p-calendar {
      width: 100%;
    }
  }
}

.task-modal-date-picker {
  .p-calendar {
    width: 100%;
  }
  .p-datepicker-trigger {
    position: absolute;
    border-radius: 3px 0 0 3px !important;
  }
  .p-inputtext {
    margin-left: 33px;
    border-radius: 0 3px 3px 0 !important;
  }
}
