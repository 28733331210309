.p-progressbar__height {
  .p-progressbar {
    height: 12px !important;
  }
}

.p-progressbar__radius {
  .p-progressbar {
    border-radius: var(--borderRadius) !important;
  }
}

.p-progressbar-value__radius {
  &.p-progressbar {
    .p-progressbar-value {
      border-radius: var(--borderRadius);
    }
  }
}

.p-progressbar__background {
  .p-progressbar {
    background-color: #b3c1c3;
  }
}

.p-progressbar__dark {
  &.p-progressbar {
    .p-progressbar-value {
      background: var(--darkColor);
    }
  }
}

.p-progressbar__30 {
  &.p-progressbar {
    .p-progressbar-value {
      background: #d95c5c;
    }
  }
}

.p-progressbar__50 {
  &.p-progressbar {
    .p-progressbar-value {
      background: #e6bb48;
    }
  }
}

.p-progressbar__80 {
  &.p-progressbar {
    .p-progressbar-value {
      background: #b4d95c;
    }
  }
}

.p-progressbar__100 {
  &.p-progressbar {
    .p-progressbar-value {
      background: #66da81;
    }
  }
}

.ready-to-submit-progressbar__color {
  &.p-progressbar {
    .p-progressbar-value {
      background: var(--darkColor);
    }
  }
}
