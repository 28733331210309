.p-tree {
  background-color: var(--cardBackGround);
}

.p-tree:has(div.p-tree-empty-message) {
  background-color: white;
  border: none;
  border-bottom: 1px solid #e9ecef;
}

.p-treenode-content {
  color: var(--darkColor);
}

.p-treenode-content {
  border-radius: var(--borderRadius) !important;
  background-color: white;
  margin-block: 0.1rem;
  padding-block: 0.4rem !important;
}

.p-tree-toggler-icon {
  color: black;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none;
}

.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}

.p-treenode-leaf:has(.border__red-tree) {
  .p-treenode-content {
    border: 1px solid var(--dangerColor);
  }
}

.p-tree-empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #495057;
  font-weight: 700;
  font-size: 1.14rem;
}

app-compare {
  p-table {
    .p-tree {
      background-color: white;
      border: 0;
      padding: 0;

      .p-treenode-content {
        background: var(--cardBackGround);
      }
    }
  }
}
