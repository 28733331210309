.p-tabview {
  .p-tabview-nav-container {
    font-size: 1.2rem;
  }

  .p-tabview-panels {
    padding: 1rem 0 0 0;
  }

  .p-tabview-nav {
    li.p-highlight .p-tabview-nav-link {
      color: var(--lightColor);
      border-color: var(--lightColor);
    }

    li {
      .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
      }

      &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        border-color: var(--lightColor);
      }
    }
  }

  .p-tabview-nav-btn.p-link {
    color: var(--lightColor);

    &:focus {
      box-shadow: inset 0 0 0 0.2rem var(--lightColor);
    }
  }
}

app-calculation {
  .calculation__tabs {
    .p-tabview-nav-container {
      position: unset;
    }

    .p-tabview-panels {
      height: calc(100% - 44px);
      min-height: unset;

      .p-tabview-panel {
        overflow: hidden;
        height: 100%;
      }
    }
  }
}

.company-details {
  .p-tabview-nav-container {
    position: sticky;
    top: -15px;
    z-index: 7;
  }

  .p-tabview-panels {
    min-height: min-content;
  }
}

.parent-tab-view {
  div {
    .p-tabview-panels {
      padding: 0;
    }
  }
}
