.stepper {
  &.p-accordion {
    .p-accordion-header {
      &.p-highlight {
        .p-accordion-header-link {
          border-style: none none none none;
          background-color: var(--cardBackGround);
          box-shadow: 0 0 0 0;
        }
      }

      .p-accordion-header-link {
        border-style: none none none none;
        background-color: var(--cardBackGround);
        box-shadow: 0 0 0 0;

        &::before {
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          left: 0;
          background-color: #0000001f;
          z-index: 5;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .p-accordion-toggle-icon {
        display: none;
      }

      .p-accordion-header-link > div {
        margin-left: 0.5rem;
      }
    }

    p-accordiontab {
      &:first-child {
        .p-accordion-header {
          .p-accordion-header-link::after {
            content: '';
            width: 2px;
            height: 50%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--cardBackGround);
            z-index: 5;
          }
        }
      }

      &:last-child {
        .p-accordion-header {
          .p-accordion-header-link::after {
            content: '';
            width: 2px;
            height: 50%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: var(--cardBackGround);
            z-index: 5;
          }
        }
      }

      .p-accordion-content {
        border-left-color: #0000001f;
        border-style: none none none solid;
        background: transparent;
      }

      &:last-child {
        .p-accordion-content {
          border-left-color: transparent;
          border-style: none none none solid;
        }
      }
    }
  }

  .todo__list-accordion.p-accordion
    p-accordiontab:last-child
    .p-accordion-content {
    border: 1px solid rgb(231, 228, 228) !important;
  }
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: -14px;
  z-index: 6;
}
