@layer defaultLayer, primeng;

@layer defaultLayer {
  @import 'assets/css/circle';
  @import 'src/assets/css/common/card';
  @import 'assets/css/common/html-element';
  @import 'assets/css/common/status-color';
  @import 'assets/css/common/loader';

  @mixin calc_customHeight($height) {
    height: calc(100% - $height);
  }

  $colors: 'lightColor', 'lightColor25', 'darkColor', 'darkAccentColor',
    'whiteColor', 'whiteOnDarkColor', 'hoverColor', 'cardBackGround',
    'borderColor', 'borderRadius', 'successColor', 'alertColor', 'infoColor',
    'dangerColor', 'darkSecondaryColor';

  @each $color in $colors {
    .color-#{$color} {
      color: var(--#{$color}) !important;
    }
    .bg-#{$color} {
      background-color: var(--#{$color}) !important;
    }
  }

  .color-primary {
    color: var(--darkColor) !important;
  }

  .color-secondary {
    color: var(--lightColor) !important;
  }
  .color-darkAccentColor {
    color: var(--darkAccentColor);
  }
  .color-darkSecondaryColor {
    color: var(--darkSecondaryColor);
  }

  .bg-primary {
    background-color: var(--darkColor) !important;
  }
  .bg-darkAccentColor {
    background-color: var(--darkAccentColor);
  }
  .bg-darkSecondaryColor {
    background-color: var(--darkSecondaryColor);
  }

  .bg-secondary {
    background-color: var(--lightColor) !important;
  }
  .bg-card {
    background-color: var(--cardBackGround);
  }

  .border-primary {
    border: 1px solid var(--darkColor) !important;
  }

  .border-secondary {
    border: 1px solid var(--lightColor) !important;
  }
  .border-white {
    border: 1px solid var(--whiteColor);
  }
  .border-darkSecondary {
    border: 1px solid var(--darkSecondaryColor);
  }

  .color-indicator-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .color-indicator-sub {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }

  .bg-green-80 {
    background-color: #ecf9ee !important;
  }

  .bg-blue-80 {
    background-color: rgba(63, 96, 215, 0.08) !important;
  }

  .bg-basenet {
    background-color: #e8f0ff !important;
  }

  .bg-cyan-80 {
    background-color: rgba(227, 253, 255, 0.56) !important;
  }

  .bg-syneton {
    background-color: #d7f0fb !important;
    color: var(--darkColor);
  }

  .min-width-50 {
    min-width: 50% !important;
  }

  .option-item {
    padding: 8px 0;
    cursor: pointer;
    outline: none;
    font-weight: 400;
    color: #7d7d7d;

    span {
      margin-right: 5px;
    }
  }

  .option-item:hover {
    color: var(--darkColor);
  }

  .w-20 {
    width: 20% !important;
  }

  .cursor-not-allow {
    cursor: not-allowed !important;
  }

  .header-text {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .word-break-wrap {
    word-break: break-word;
    word-wrap: break-word;
  }

  .greenBackground {
    background-color: #38c976;
  }

  .saffronBackGround {
    background-color: #ff9700 !important;
  }

  .redBackground {
    background-color: #fe5050;
  }

  .w-available {
    width: -webkit-fill-available;
  }

  .dot {
    position: absolute;
    bottom: 1px;
    right: 10px;
    border: 1.5px solid var(--whiteColor);
    border-radius: 50%;
    height: 10px !important;
    width: 10px !important;
  }

  .upload-button {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    background-color: #9094a3;
    border-radius: 50%;
    color: white;
  }

  .field-required:after {
    content: '*';
    color: red;
  }

  .smile,
  .close {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .textarea__icon {
    position: absolute;
    right: 5px;
    bottom: 10px;
  }

  .content-separator {
    height: 1px;
    background-color: var(--borderColor);
  }

  .additional__attachments-button-width {
    max-width: 200px;
  }

  .bot-icon-dimension {
    width: 75px;
    height: 75px;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .customer-list-label {
    margin: 0em 0.15em;
    padding: 0.25em 0.8em;
    font-weight: bold;
    font-size: 0.85rem;
    border-radius: 0.3rem;
  }

  // Override default li and ul padding in prime ng component(p-autoComplete)
  .search-bar > span > div > ul,
  .search-bar > span > div > ul > li {
    padding: 0px !important;
  }

  // access children of (search-bar) to override default div in prime ng component
  .search-bar > span > div {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
    max-height: fit-content !important;
  }

  .search-bar > span {
    height: 42px;
  }

  // disable loader of the search bar
  .search-bar > span > .p-autocomplete-loader {
    display: none !important;
  }

  // contract-v2 form modal component all override style

  ngx-extended-pdf-viewer button:focus,
  ngx-extended-pdf-viewer a:focus,
  ngx-extended-pdf-viewer input:focus,
  ngx-extended-pdf-viewer select:focus {
    @extend .border-primary;
  }

  ngx-extended-pdf-viewer {
    position: relative;
    z-index: 2 !important;
  }

  .image-dimension {
    margin: 0 auto;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    opacity: 1;
  }

  .img-container {
    position: relative;
    width: max-content;
    height: max-content;
  }

  .icon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .img-container:hover {
    img {
      opacity: 0.5;
    }
    .icon-container {
      opacity: 1;
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .square-box {
    width: 12px;
    height: 12px;
  }

  .calculation__container-height {
    height: calc(100vh - 185px);
  }

  .calculation_tax_machine {
    border: none;
    @include calc_customHeight(10px);
  }

  .simulation_tax_machine {
    border: none;
    @include calc_customHeight(109px);
  }

  .border-collapse {
    border-collapse: collapse;
  }

  .iframe-video {
    width: 500px;
    height: 350px;
  }

  .border-collapse {
    border-collapse: collapse;
  }

  //override class for the mobile number input
  .iti.iti--allow-dropdown.iti--separate-dial-code {
    width: 100%;
  }

  .iti__flag-container {
    position: static;
    padding: 0;
  }

  .iti__selected-flag {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
  }

  .iti__country-list {
    width: 25vw !important;
    top: 100%;
  }

  .information__input-div {
    border-right: 2px solid var(--darkColor);
  }

  .h-52rem {
    height: 52rem;
  }

  .sources-import-back-button {
    color: var(--darkSecondaryColor);
    background-color: var(--whiteColor);
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }

  .sources-import-header {
    line-height: 27px;
    font-size: 18px;
  }

  .communication-details-markdown-renderer {
    p,
    h1,
    h2,
    h3 {
      margin: 0 !important;
    }
  }
  .text-editor {
    .ql-tooltip {
      left: 40px !important;
    }
  }

  @media (max-width: 768px) {
    .iframe-video {
      width: 100%;
      height: 200px;
    }
  }

  @import 'assets/css/override-scss/material-override/modalOverRide';
  @import 'assets/css/override-scss/primeng-override/chipsOverRide';
  @import 'src/assets/css/override-scss/primeng-override/progressbar';
  @import 'src/assets/css/override-scss/primeng-override/tooltip-container';
}
