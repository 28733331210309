input,
textarea {
  width: 100%;
}

.ql-container {
  font-family: Poppins;
  font-size: 14px;
}

.agenda__event-date__picker {
  width: 100%;
}

.agenda__event-date__picker.p-calendar .p-inputtext {
  text-align: center;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.multi__select-width.p-multiselect {
  width: 100%;
}

.questionnaire__allocation-width.p-multiselect {
  width: 50rem;
}

.existing__customers-list.p-multiselect {
  width: 60%;

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
  }
}

.p-multiselect .p-multiselect-panel {
  max-width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: pre-line;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #daeeec;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin-right: 0.5rem;
}

.p-multiselect-chip .p-multiselect-label {
  display: block !important;
}

.error-message.p-inline-message.p-inline-message-error {
  margin-top: 5px;
}

.task__flow__select-width.p-dropdown {
  width: 30%;
}

.dashboard__language__select-width.p-dropdown {
  background: var(--darkColor);
  border: var(--darkColor);

  .p-inputtext,
  .p-dropdown-trigger {
    color: white;
  }

  .p-inputtext {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.tco__select-width.p-dropdown {
  width: 20%;
}

.search__bar-width.p-dropdown,
.p-inputgroup .p-inputwrapper > .p-component {
  width: 100%;
  background: none;
  border: none;

  .p-inputtext {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.team-home-search-bar {
  .p-inputtext {
    &:enabled {
      &:hover {
        border-color: transparent;
      }

      &:focus {
        border-color: transparent;
      }
    }
  }
}

p-scroller > div:first-child {
  max-height: 190px;
}

.search-bar {
  .p-inputtext {
    text-overflow: ellipsis;
    padding-left: 3rem;
  }

  .p-autocomplete-panel {
    max-height: 600px !important;
  }

  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0;
  }

  .p-autocomplete:has(.p-autocomplete-item, .p-autocomplete-empty-message) {
    .p-inputtext {
      border-radius: 3px 3px 0 0;
    }
  }

  .p-autocomplete-empty-message {
    padding: 1rem !important;
  }

  .p-autocomplete-loader {
    display: none;
  }

  &.mic-input-visible {
    .p-inputtext {
      padding-right: 3rem;
    }
  }
}

.p-autocomplete-panel {
  border-radius: 0 0 3px 3px;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  padding: 0.5rem 0 0 0;
}

.p-inputgroup-addon {
  padding: 0;
  min-width: auto;
}

.language__select-width {
  &.p-dropdown {
    .p-inputtext {
      padding: 0.5rem 0 0.5rem 0.5rem;
    }
  }
}

// START DROPDOWN OVERRIDE SCSS
.select-width {
  &.p-dropdown {
    width: 100%;
  }
}

.p-dropdown-panel {
  min-width: 100%;
  width: min-content;

  .p-dropdown-items {
    .p-dropdown-item {
      &.p-highlight {
        background: #c9e0e4;
      }
    }
  }
}

.p-dropdown {
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: 0 0 0 0.2rem #5b8d97;
      border-color: #5b8d97;
    }

    &:hover {
      border-color: var(--primaryColor);
    }
  }
}

.p-inputgroup-addon {
  .p-overlay .p-component {
    min-width: 250px;
  }
}

// END DROPDOWN OVERRIDE SCSS

// START MULTISELECT OVERRIDE SCSS
.p-multiselect {
  &:not(.p-disabled):hover {
    border-color: var(--lightColor);
  }
}

// END MULTISELECT OVERRIDE SCSS

app-contract-wise-customer {
  .p-inputgroup {
    width: fit-content;
  }
}

.p-dropdown {
  width: -webkit-fill-available;
}

.webflow-language-dropdown {
  .p-dropdown {
    .p-dropdown-label {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }
  }
}

app-agenda {
  .agenda__event__full__day-range__picker {
    width: 100%;
  }

  .agenda__event__full__day-range__picker.p-calendar .p-inputtext {
    text-align: center;
  }

  .fc-direction-ltr {
    height: calc(100vh - 135px);
  }

  .fc {
    &.fc-theme-standard {
      .fc-toolbar {
        .fc-button {
          &.fc-button-active {
            background-color: var(--darkColor);
            border: 0;
          }

          &:enabled {
            &:hover {
              background-color: var(--darkColor);
            }
          }

          &:disabled {
            background-color: var(--darkColor);
            border: 0;
          }

          &:focus {
            box-shadow: none !important;
          }

          &.fc-timeGridWeek-button {
            &:hover {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
              color: var(--whiteColor);
            }

            &.fc-button-active {
              &:hover {
                background-color: var(--darkColor);
                border-color: var(--darkColor);
                color: var(--whiteColor);
              }

              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }

          &.fc-dayGridMonth-button {
            &:hover {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
              color: var(--whiteColor);
            }

            &.fc-button-active {
              &:hover {
                background-color: var(--darkColor);
                border-color: var(--darkColor);
                color: var(--whiteColor);
              }

              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }

          &.fc-timeGridDay-button {
            &:hover {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
              color: var(--whiteColor);
            }

            &.fc-button-active {
              &:hover {
                background-color: var(--darkColor);
                border-color: var(--darkColor);
                color: var(--whiteColor);
              }

              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }
        }
      }

      .fc-view-harness {
        .fc-event {
          &.fc-daygrid-block-event {
            background-color: var(--darkColor);
            border-color: var(--darkColor);
          }

          &.fc-daygrid-dot-event {
            .fc-daygrid-event-dot {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }
        }
      }
    }

    .fc-button-primary {
      background-color: var(--whiteColor);
      border-color: var(--darkColor);
      color: var(--darkColor);
    }
  }

  .fc-v-event {
    background-color: var(--darkColor);
    border-color: var(--darkColor);
  }

  .p-button {
    &:focus {
      box-shadow: 0 0 0 0.2rem #5b8d97;
    }
  }

  .p-selectbutton .p-button.p-highlight,
  .p-selectbutton .p-button.p-highlight:hover {
    background: var(--darkColor);
    border-color: var(--darkColor);
    color: var(--whiteColor);
  }
}

.sidebar-menu {
  .p-component {
    font-family: 'Poppins' !important;
    font-size: 15px;
  }

  .sidebar-panel__menu.p-panelmenu
    .p-panelmenu-header
    .p-panelmenu-header-content
    > a,
  .p-panelmenu .p-panelmenu-header.p-panelmenu-header-content .p-highlight > a,
  .p-menuitem .p-menuitem-content {
    background: var(--darkColor);
    border: none;
    font-weight: normal;
    cursor: pointer;
    color: var(--whiteOnDarkColor);
  }

  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover,
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link:not(.p-disabled):hover {
    background: var(--darkAccentColor);
    border: none;
    color: var(--whiteColor);
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    .p-menuitem-text,
    .p-menuitem-icon {
      color: var(--whiteOnDarkColor);
    }
  }

  .p-panelmenu
    .p-panelmenu-header
    .p-panelmenu-header-content
    .p-panelmenu-header-action.p-menuitem-link-active,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link:not(.p-disabled):hover,
  .p-panelmenu
    .p-panelmenu-panel
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link-active {
    & {
      background: var(--darkAccentColor);
    }

    .p-menuitem-text,
    .p-menuitem-icon {
      color: var(--whiteColor);
    }
  }

  .p-toggleable-content {
    padding: 0 0 0 12px;
  }

  .sidebar-panel__menu.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link:focus,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content.p-menuitem-link:active,
  .p-panelmenu .p-panelmenu-header > a:focus {
    box-shadow: none;
  }

  .sidebar-panel__menu.p-panelmenu .p-panelmenu-content {
    padding: 0;
    border: 0;
    background: var(--darkColor);
  }

  .sidebar-panel__menu.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link {
    padding-left: 30px;
  }

  .sidebar-panel__menu.p-panelmenu .p-menuitem-text {
    line-height: 1.5 !important;
  }

  .p-panelmenu
    .p-panelmenu-panel
    .p-panelmenu-header
    .p-panelmenu-header-content {
    border: none;
  }

  .sidebar-panel__menu {
    .p-panelmenu-header > a .p-menuitem-icon {
      font-size: 18px;
      padding-right: 4px;
    }

    .p-menuitem-icon {
      font-size: 17px;
      padding-right: 4px;
    }

    .p-icon-wrapper {
      order: 1;
      margin-left: 0.5rem;
      margin-right: 0;
    }

    .p-menuitem-text {
      flex-grow: 1;
    }
  }

  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: var(--whiteOnDarkColor);
  }

  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    background-color: var(--darkColor);
  }
}

.contract__form-breadcrumb.p-breadcrumb {
  border: none;
  border-radius: unset;
}

.p-component {
  font-family: Poppins, serif;
  font-size: 1rem;
}

.p-blockui-document.p-component-overlay {
  z-index: 9999;
}

.color__picker-input.p-inputtext {
  color: rgb(112, 112, 112);
  margin-left: 10px;
  padding: 0.09rem 0.4rem;
  width: 45%;
}

.quiz__builder__color__picker-input.p-inputtext {
  color: #707070;
  position: absolute;
  width: 6%;
  padding: 0;
  font-size: 12px;
  text-align: center;
}

.p-sidebar {
  .p-sidebar-header,
  .p-sidebar-footer {
    display: none;
  }

  .p-sidebar-content {
    padding: 0;
  }
}

.p-menubar {
  .p-submenu-list {
    z-index: 100 !important;
  }
}

.builder-step__select-width {
  &.p-dropdown {
    width: calc(100% - 35px) !important;
  }
}

.builder-input-mapping__select-width {
  &.p-dropdown {
    width: calc(100% - 65px) !important;
  }
}

.builder-api-input-mapping__select-width {
  &.p-dropdown {
    width: calc(100% - 70px) !important;
  }
}

.builder-input-width {
  width: calc(100% - 30px) !important;
}

.message__container {
  .p-message {
    .p-message-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.submission-file-upload-dialog-table {
  overflow: auto !important;
  max-height: 200px !important;
}

.loader {
  &.p-component-overlay {
    background-color: rgb(255 255 255 / 40%) !important;
  }
}

.p-tieredmenu .p-menuitem-active {
  p-tieredmenusub {
    .p-submenu-list {
      left: 0 !important;
      top: 35px !important;
    }
  }
}

.aos-communication {
  &.p-overlaypanel {
    overflow: hidden;
    width: 165px;
    margin-top: 2px;
  }
  .p-overlaypanel-content {
    padding: 1rem 0;
  }
  .option-item {
    color: black;
    font-weight: 500;
  }
  .option-item:hover {
    background: #e9ecef;
  }
}

.task-modal-chip {
  &.p-chip {
    background-color: var(--cardBackGround) !important;
    color: #06323b !important;
  }
}

.communication-details-chip {
  &.p-chip {
    padding: 2px 0 !important;
    .p-chip-icon {
      margin: 0 11px !important;
    }
    .p-chip-text {
      margin-right: 11px !important;
    }
  }
}

.task-template-overlay-panel {
  .p-overlaypanel-content {
    padding: 0;
  }
  &.p-overlaypanel {
    margin-top: 1px;
    border-radius: 3px 3px 0 0 !important;
  }
  &.p-overlaypanel {
    &:before,
    &:after {
      content: none !important;
    }
  }
}

.ccf-note-overlay {
  .p-overlaypanel-content {
    padding: 0.8rem !important;
  }
}

.multi-select-search-bar {
  .p-autocomplete-multiple {
    min-height: 42px;
    max-height: 110px;
    height: unset;
  }
}

.builder-color-picker {
  .p-colorpicker {
    width: 30px;
  }
}

.p-colorpicker-panel .p-colorpicker-hue {
  background: transparent url('../../../../assets/images/custom-hue.png') no-repeat left top !important;
}

@media screen and (max-width: 768px) {
  .p-sidebar-right {
    width: fit-content !important;
  }
}

@import 'panelmenu';
@import 'muliselect';
@import 'datepicker';
@import 'checkbox';
@import 'inputfileds';
@import 'button';
@import 'accordion';
@import 'fileUpload';
@import 'dialog';
@import 'menubar';
@import 'toast';
@import 'number-input';
@import 'tab-view';
@import 'dashboard';
@import 'stepper';
@import 'tab-view';
@import 'badge';
@import 'timeline';
@import 'table';
@import 'breadcrumb';
@import 'card';
@import 'speed-dial';
@import 'tag';
@import 'dropdown';
@import 'tree-view';
@import 'auto-complete';
@import 'icons';
