.p-panelmenu
  .p-panelmenu-header
  .p-panelmenu-header-content
  .p-panelmenu-header-action {
  padding: 0.7rem;
}

.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-content
  .p-menuitem-link {
  padding: 0.4rem 1rem;
}

.sidebar-menu {
  max-height: calc(100vh - 95px) !important;
}

//closed sidebar css
.closed {
  .ch-side-head {
    .ch-head-image2 {
      margin-right: 0;
    }
  }

  .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-menuitem-icon {
    margin-right: 0;
    padding-right: 0;
  }

  .p-menuitem-link {
    padding-left: 1rem !important;
    justify-content: center;
  }

  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link {
    padding: 0.75rem 1rem;
  }

  .p-panelmenu-header-action,
  .p-menuitem-link {
    position: relative;

    &:hover {
      .p-menuitem-text {
        visibility: visible !important;
      }
    }
  }

  .p-menuitem-text {
    display: block !important;
    position: absolute;
    left: 100px;
    top: 1px;
    text-align: left !important;
    background-color: var(--darkColor);
    border-radius: 4px !important;
    word-break: keep-all;
    white-space: nowrap;
    padding: 6px;
    visibility: hidden;
    z-index: 9999;
  }
}

.navbar-panel__menu.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-left: 0.5rem;
  margin-right: 0;
  order: 1;
}

.p-panelmenu .p-panelmenu-panel:has(.active-customer) {
  border-left: solid 3px var(--lightColor);

  overflow: hidden;

  animation: 400ms active-customer-menu-appear ease;
}

.p-panelmenu .p-panelmenu-panel:has(.active-customer.is-hidden) {
  max-height: 0;
  margin-bottom: 0;
  animation: 400ms active-customer-menu-appear ease reverse;
}

.p-panelmenu .p-panelmenu-panel:has(.active-customer.is-hidden.is-not-loaded) {
  animation: unset;
}

.sidebar-menu .p-menuitem-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-menu .p-panelmenu .p-panelmenu-panel:has(.is-section-heading) {
  margin-top: 0.5rem;
}

.sidebar-menu .p-panelmenu .p-panelmenu-panel .is-section-heading a {
  text-transform: uppercase;
  color: var(--lightColor) !important;
  font-size: 12px;
  font-weight: 600 !important;
  cursor: default !important;
}

.sidebar-menu
  .sidebar-panel__menu.p-panelmenu
  .p-panelmenu-header.is-section-heading
  > a {
  font-weight: bold;
  cursor: default;
  padding: 0 0.5rem 0.25rem 0.5rem;
  color: var(--lightColor);
}

@keyframes active-customer-menu-appear {
  from {
    max-height: 0;
  }

  to {
    max-height: 150px;
  }
}
