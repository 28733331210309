
.primary__card  {
  border-radius: var(--borderRadius);
  padding: 10px;
  background: var(--cardBackGround);

  .primary__card-header {
    font-weight: bold;
    font-size: 1.28571429em;
    margin-top: -0.21425em;
    margin-bottom: -0.21425em;
    line-height: 2.285714em;
    display: block;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  .primary__card-content {
    flex-grow: 1;
    border: none;
    background: none;
    margin: 0em;
    padding: 1em 1em;
    box-shadow: none;
    font-size: 1em;
    border-radius: 0em;
  }
}

.primary__card-white {
  background: var(--whiteColor) !important;
}

.primary__card-gradient {
  background: linear-gradient(
      -90deg,
      var(--lightColor) 0%,
      var(--darkColor) 100%
  );
  padding: 25px !important;
}
