.p-autocomplete {
  width: 100%;

  ul {
    width: 100%;
  }

  .p-autocomplete-token:has(.chip__disabled) {
    .p-autocomplete-token-icon {
      display: none;
    }
  }
}

.task-template {
  .p-overlay {
    width: 100% !important;
  }
  &.p-autocomplete {
    padding: 1rem !important;
  }
  .p-autocomplete-clear-icon {
    right: 1.5rem !important;
  }
  .p-autocomplete-loader {
    display: none !important;
  }
}

.search-bar {
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    border-radius: 15px;
    background-color: var(--cardBackGround);
  }
  .p-autocomplete-token-icon {
    display: none;
  }
}
