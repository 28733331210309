// START CHECKBOX OVERRIDE SCSS
.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      border-color: var(--darkColor);
      background: var(--darkColor);
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        border-color: var(--darkColor);
        box-shadow: 0 0 0 0.2rem #5b8d97;
      }
      &.p-highlight {
        &:hover {
          border-color: var(--darkColor);
          background: var(--darkColor);
        }
      }
      &:hover {
        border-color: var(--darkColor);
      }
    }
  }
}
// END CHECKBOX OVERRIDE SCSS
