.slider,
.dashboard__sidebar.p-sidebar {
  .p-sidebar-header {
    padding: 0;
  }
  .p-sidebar-content {
    padding: 0;
  }
}

.dashboard__sidebar.p-sidebar {
  position: relative;
  .p-sidebar-content {
    position: static;
    overflow: hidden;
    height: 100%;
    .sidebar {
      height: 100%;
    }
  }
}

.dashboard__sidebar.p-sidebar-left {
  width: fit-content;
}

@media screen and (max-width: 960px) {
  .dashboard__sidebar.p-sidebar {
    position: fixed;
  }
  .dashboard__sidebar__container {
    width: 0 !important;
    transition: none !important;
  }
}

.dashboard__sidebar__container {
  flex-shrink: 0;
  transition: width 400ms cubic-bezier(0, 0, 0.2, 1);

  &.is-open {
    width: 260px;
  }
  &.is-closed {
    width: 0;
  }
}

.note-list-content {
  width: 100% !important;
}

.main-content-body {
  width: calc(100% - 260px) !important;
}
