.launch-icon {
  &.p-dropdown {
    height: 37px;
  }
}
p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #ced4da !important;
}
p-dropdown.ng-dirty.ng-invalid.ng-touched > .p-dropdown {
  border-color: #fe5050 !important;
}
