.tow-codes-tooltip,
.xml-info-tooltip {
  max-width: 25% !important;
}

.tow-proposal-tooltip {
  max-width: 28% !important;
}

.rrn-tooltip {
  max-width: 30% !important;
}

@media (max-width: 576px) {
  .tow-codes-tooltip,
  .xml-info-tooltip {
    max-width: 30% !important;
  }

  .tow-proposal-tooltip {
    max-width: 35% !important;
  }
}

@media (max-width: 1024px) {
  .tow-codes-tooltip,
  .xml-info-tooltip {
    max-width: 55% !important;
  }

  .tow-proposal-tooltip {
    max-width: 60% !important;
  }
}
