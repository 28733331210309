// START MENUBAR OVERRIDE SCSS
.dashboard-menubar.p-menubar {
  background: none;
  border: none;
  padding: 0;
  .pi {
    font-size: 16px;
  }
}

.dashboard-menubar.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: none;
}

.dashboard-menubar.p-menubar {
  .p-submenu-list {
    z-index: 1200 !important;
  }
}

.p-menubar {
  .p-menubar-root-list {
    & > .p-menuitem {
      & > .p-menuitem-content .p-menuitem-link {
        padding: 12px 16px;
      }
    }
  }
}
// END MENUBAR OVERRIDE SCSS
