.mat-drawer-container,
.mat-drawer-backdrop.mat-drawer-shown,
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: transparent;
}

.mat-tab-body-content {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--darkColor);
}

.mat-tab-header {
  background-color: var(--cardBackGround) !important;
  border-radius: var(--borderRadius);
}

.mat-tab-group.mat-background-primary {
  .mat-tab-header-pagination-chevron {
    border-color: var(--darkColor) !important;
  }

  .mat-tab-label {
    color: var(--darkColor) !important;
  }

  .mat-tab-link {
    @extend .mat-tab-label;
  }

  .mat-tab-label-active {
    opacity: 1 !important;
    color: var(--whiteColor) !important;
    background-color: var(--darkColor);
    border-radius: var(--borderRadius);
  }
}

.mat-tab-body-wrapper {
  margin-top: 10px;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: var(--cardBackGround);
}

.mat-tree-node {
  background: var(--whiteColor);
  margin: 5px 0;
  border-radius: var(--borderRadius);
  min-height: auto !important;
}

.mat-tree {
  background: var(--cardBackGround);
  padding: 5px 10px;
  border-radius: var(--borderRadius);
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 10px 10px !important;
  border-radius: var(--borderRadius);
  background: none;
}

.mat-form-field-wrapper,
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
}

.mat-form-field-underline {
  display: none;
}

.mat-chip-list-wrapper {
  display: block !important;
}

.mat-tab-body {
  min-height: 60vh;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--darkColor) !important;
}

.mat-checkbox-disabled {
  .mat-checkbox-inner-container {
    opacity: 0.5;
  }
}

.mat-calendar-body-selected {
  background: var(--lightColor);
}

.mat-chip-list-wrapper {
  display: flex !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--darkColor);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--whiteColor);
}

//for the datepicker show in form loop model
.cdk-overlay-container {
  z-index: 9999;
}
