.delete__confirmation {
  &.p-toast {
    .p-toast-message {
      &.p-toast-message-warn {
        background: #ffffff;
        border: solid var(--darkColor);
        border-width: 0 0 0 6px;
        color: #000000;
      }
      .p-toast-icon-close {
        color: #000000;
      }
    }
  }
}
