$background-color_1: white;

.advanced__settings-accordion.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      border: none;
      background: none;

      .p-accordion-toggle-icon {
        order: 1;
        font-size: 12px;
        margin-left: 3px;
      }
    }

    &:not(.p-disabled).p-highlight {
      &:hover {
        .p-accordion-header-link {
          border: none;
          background: none;
          border: none;
          background: none;
        }
      }

      .p-accordion-header-link {
        border: none;
        background: none;
        border: none;
        background: none;
      }
    }

    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            border: none;
            background: none;
            border: none;
            background: none;
          }
        }
      }
    }
  }

  .p-accordion-content {
    border: none;
  }
}

.todo__list-accordion.p-accordion {
  .p-accordion-header {
    border: 1px solid rgb(231, 228, 228);
    border-radius: 8px;

    .p-accordion-header-link {
      background-color: $background-color_1 !important;
      padding: 0;

      .p-accordion-toggle-icon {
        display: none;
      }
    }
  }

  p-accordiontab {
    &:last-child {
      .p-accordion-content {
        background-color: $background-color_1;
        border: 1px solid rgb(231, 228, 228);
        border-top-color: transparent;
      }
    }
  }
}

.personal__details-accordion.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 0;
      border: none;
      background: none;

      .p-accordion-toggle-icon {
        order: 1;
        font-size: 12px;
        margin-left: 3px;
      }
    }

    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: none;
            border: none;
          }
        }
      }
    }

    &:not(.p-disabled).p-highlight {
      &:hover {
        .p-accordion-header-link {
          background: none;
          border: none;
        }
      }

      .p-accordion-header-link {
        background: none;
        border: none;
      }
    }
  }

  .p-accordion-content {
    padding: 0;
    margin-top: 1rem;
    border: none;
  }
}

.p-accordion {
  .p-accordion-header {
    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.kamigo-accounting-apps-accordion {
  .p-accordion-tab {
    .p-accordion-header {
      opacity: unset;
    }
  }
  .p-accordion-header .p-accordion-toggle-icon {
    display: none;
  }
}
