li:has(.aos-customer-import-fields-border) {
  border-bottom: 1px solid #cdd3d5 !important;
}

li:has(.aos-customer-multiselect-item) {
  background: transparent !important;
  box-shadow: unset !important;
}

.aos-customer-multiselect > div {
  width: 170px;
}

.builder__multiselect-dropdown {
  .p-multiselect-label-container {
    height: 34px !important;
  }
}
