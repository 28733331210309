.signed--color {
  color: #38c976;
}

.not__singed {
  background-color: #fe5050;
}

.sent_for_signing {
  background-color: #ff9700;
}

.sent_for_signing--color {
  color: #ff9700;
}

.partial__singed {
  background-color: #ff9700;
}

.partial__singed--color {
  color: #ff9700;
}

.contract__in_progress {
  background-color: #206dff;
}

.contract__in_progress__color {
  color: #206dff;
}

.status-not-sent,
.status-draft {
  background: var(--darkColor);
}

.status-sent {
  background: #7f7f7f;
}

.status-in-review,
.status-to-process {
  background: #ff9700;
}

.status-submitted,
.status-completed {
  background: #38c976;
}

.calculation-in-progress {
  background: #e68902;
}

.calculation-in-review {
  background: #dbaf18 !important;
}

.calculation-sent {
  background: #875204;
}

.calculation-approved {
  background: #7fdba7;
}

.calculation-rejected {
  background: #dd4b39;
}

.calculation-ready-for-filing {
  background: #00a7d3;
}

.calculation-TOW-ready {
  background: #0058aa;
}

.calculation-processed {
  background: #38c976;
}

.calculation-not-sent {
  background: var(--darkColor);
}

.calculation-done {
  background: #bf7304;
}

.calculation-processed {
  background: #38c976;
}

.calculation-locked {
  background: #ff1414;
}

.aos-communication-status {
  .sent,
  .expired,
  .unread {
    background-color: #7f7f7f;
  }

  .completed,
  .read {
    background: #38c976;
  }

  .to-process {
    background: #ff9700;
  }

  .reopen {
    background: var(--infoColor);
  }

  .declined {
    background: #fe5050;
  }
}
